import React, { useState } from 'react';
import { Select } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { STATUS_LIST } from '../shared/ApplicationConstants';
function Details({
  dataFromDashboard,
  allAssignee,
  originalAssignee,
  handleAssigneeChange,
  handleStatusChange,
}) {
  const { Option } = Select;
  const [openAssignee, setOpenAssignee] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);

  const allAssigneeOption = [<Option key={null}>Unassigned</Option>];
  (allAssignee || []).forEach((assignee) => {
    allAssigneeOption.push(
      <Option key={assignee.id}>
        {assignee.first_name} {assignee.last_name}
      </Option>
    );
  });

  const statuses = [];
  STATUS_LIST.forEach((status) => {
    statuses.push(<Option key={status.key}>{status.value}</Option>);
  });

  const assignee =
    originalAssignee &&
    `${originalAssignee.first_name} ${originalAssignee.last_name}`;
  const status = dataFromDashboard && dataFromDashboard.status;

  return (
    <table className="simpleTable detailsTable">
      <thead>
        <tr>
          <th colSpan="2">Details</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Error Type:
            {` ${(dataFromDashboard && dataFromDashboard.error_type) || null}`}
          </td>
        </tr>
        <tr>
          <td>
            Error ID:
            {` ${
              (dataFromDashboard && dataFromDashboard.error_type_id) || null
            }`}
          </td>
        </tr>
        <tr>
          <td>
            Assignee:
            <Select
              placeholder={assignee || 'Unassigned'}
              suffixIcon={
                <CaretDownOutlined
                  onClick={() => setOpenAssignee(!openAssignee)}
                />
              }
              open={openAssignee}
              style={{ width: 120 }}
              onChange={(e) => {
                handleAssigneeChange(e);
              }}
              onClick={() => setOpenAssignee(!openStatus && !openAssignee)}
              onBlur={() => setOpenAssignee(false)}
            >
              {allAssigneeOption}
            </Select>
          </td>
        </tr>
        <tr>
          <td>
            Status:
            <Select
              placeholder={status}
              suffixIcon={
                <CaretDownOutlined onClick={() => setOpenStatus(!openStatus)} />
              }
              open={openStatus}
              style={{ marginLeft: 15, width: 120 }}
              onChange={(e) => {
                handleStatusChange(e);
              }}
              onClick={() => setOpenStatus(!openAssignee && !openStatus)}
              onBlur={() => setOpenStatus(false)}
            >
              {statuses}
            </Select>
          </td>
        </tr>
        <tr>
          <td>
            Error Log Date:
            {` ${(dataFromDashboard && dataFromDashboard.created_at) || null}`}
          </td>
        </tr>
        <tr>
          <td>
            Request ID:
            {` ${(dataFromDashboard && dataFromDashboard.request_id) || null}`}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
export default Details;

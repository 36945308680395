import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Table, Button, Form } from 'antd';
import OriginalAccount from './OriginalAccount';
import CommentBox from '../shared/CommentBox';
import Details from './Details';
import './sfErrorDetails.css';
import { useOktaAuth } from '@okta/okta-react';
import {
  getAdditionalErrorDetailsBySalesForceReviewId,
  updateSalesForceErrorDetails,
  updateErrorRecord,
  updateSalesForceErrorDetailComments,
} from '../../apis/errorRecordsApis';

const mapComments = (records) =>
  records.map((record) => [[record.id], record.comment]);

function SfErrorDetails() {
  const history = useHistory();
  const location = useLocation();
  const { authState } = useOktaAuth();
  const accessToken = authState.accessToken?.accessToken;

  const [dataFromDashboard, setDataFromDashboard] = useState(null); // get list of all assignee and error Id from  history pathname and state
  const [errorDetails, setErrorDetails] = useState([]);
  const [originalRequestDetails, setOriginalRequestDetails] = useState({});
  const [allAssignee, setAllAssignee] = useState(null); //List of all assignee
  const [originalAssignee, setOriginalAssignee] = useState(null); // store original selected assignee object

  const [assigneeId, setAssigneeId] = useState(null); // when assignee is changed, store the id
  const [status, setStatus] = useState(null); // when status is changed, store
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState({});
  const { reviewId } = useParams();

  useEffect(() => {
    async function fetchData() {
      setDataFromDashboard(location.state.data); // Get error details for details sectiion
      setAllAssignee(location.state.allAssignee); // set List of all assignee for details section dropdown
      setAssigneeId(location.state.data.data_steward_id); // set Original Assignee id
      setStatus(location.state.data.status); //set original status

      const selectedAssignee = (location.state.allAssignee || []).find(
        (item) => item.id === location.state.data.data_steward_id
      );
      if (selectedAssignee) {
        setOriginalAssignee(selectedAssignee);
      }

      const additionalErrorDetails =
        await getAdditionalErrorDetailsBySalesForceReviewId(
          location.state.id,
          accessToken
        ); // API call to get original account information section
      setOriginalRequestDetails(additionalErrorDetails.originalRequestDetails);
      setErrorDetails(additionalErrorDetails.errorDetails);
      const mappedComments = Object.fromEntries(
        mapComments(additionalErrorDetails.errorDetails)
      );
      setComments(mappedComments);
    }
    fetchData();
  }, []);

  const getIds = (errorDetails) => {
    return errorDetails.map((errorDetail) => ({
      id: errorDetail.id,
      review_id: errorDetail.review_id,
      gln: errorDetail.grower_gln,
      sfdc: errorDetail.grower_sfdc_id,
    }));
  };

  const refreshData = async () => {
    setLoading(true);
    const errorRecords = getIds(errorDetails);
    if (errorRecords.length > 0) {
      const errorDetailsArray = await updateSalesForceErrorDetails(
        errorRecords,
        accessToken
      );
      setErrorDetails(errorDetailsArray[0]);
    }
    setLoading(false);
  };

  function handleAssigneeChange(id) {
    setAssigneeId(id);
  }

  function handleStatusChange(value) {
    setStatus(value);
  }

  const onFinish = async (comments) => {
    setLoading(true);
    await updateErrorRecord(
      {
        assigneeId: Number(assigneeId),
        originalId: dataFromDashboard.data_steward_id,
        status,
        reviewId,
      },
      accessToken
    );
    const commentsToUpdate = Object.fromEntries(
      Object.entries(comments).filter(([_, v]) => v !== null)
    );
    console.log(commentsToUpdate);
    await updateSalesForceErrorDetailComments(commentsToUpdate, accessToken);
    setLoading(false);
  };

  const columns = [
    {
      title: 'Information',
      children: [
        {
          title: 'SFDC ID',
          dataIndex: 'grower_sfdc_id',
          width: 200,
        },
        {
          title: 'SFDC FSAP Id',
          dataIndex: 'grower_fsap_id',
          width: 200,
        },
        {
          title: 'BP ID',
          dataIndex: 'grower_bp_id',
          width: 150,
        },
      ],
    },
    {
      title: 'Name & Address',
      children: [
        {
          title: 'Name',
          dataIndex: 'grower_legal_name',
          width: 150,
        },
        {
          title: 'Mailing P.O Box',
          dataIndex: 'grower_po_box',
          width: 150,
        },
        {
          title: 'Mailing Street',
          dataIndex: 'grower_mailing_address_line_1',
          width: 150,
        },
        {
          title: 'Mailing City',
          dataIndex: 'grower_mailing_city',
          width: 150,
        },
        {
          title: 'Mailing State',
          dataIndex: 'grower_mailing_state',
          width: 150,
        },
        {
          title: 'Mailing Zip',
          dataIndex: 'grower_mailing_postal_code',
          width: 150,
        },
        {
          title: 'Mailing Country',
          dataIndex: 'grower_mailing_country',
          width: 150,
        },
        {
          title: 'Shipping Street',
          dataIndex: 'grower_shipping_address_line_1',
          width: 150,
        },
        {
          title: 'Shipping City',
          dataIndex: 'grower_shipping_city',
          width: 150,
        },
        {
          title: 'Shipping State',
          dataIndex: 'grower_shipping_state',
          width: 150,
        },
        {
          title: 'Shipping Zip',
          dataIndex: 'grower_shipping_postal_code',
          width: 150,
        },
        {
          title: 'Shipping Country',
          dataIndex: 'grower_shipping_country',
          width: 150,
        },
      ],
    },
    {
      title: 'Other Information',
      children: [
        {
          title: 'Last Updated Date/Time',
          dataIndex: 'updated_at',
          width: 150,
        },
        {
          title: 'Comments',
          dataIndex: 'comments',
          width: 150,
          render: (record, row) => (
            <CommentBox
              recordId={row.id}
              comments={comments}
              setComments={setComments}
            />
          ),
        },
      ],
    },
  ];

  return (
    <section className="container">
      <Form onFinish={() => onFinish(comments)}>
        <div className="heading textCenter">Error Details</div>
        <div className="accountSection">
          <OriginalAccount apiData={originalRequestDetails} />
          <div className="details">
            <Details
              dataFromDashboard={dataFromDashboard}
              allAssignee={allAssignee}
              originalAssignee={originalAssignee}
              handleAssigneeChange={handleAssigneeChange}
              handleStatusChange={handleStatusChange}
            />
          </div>
        </div>
        <hr />

        <Table
          columns={columns}
          dataSource={errorDetails}
          rowKey={(record) => record.id}
          loading={loading}
        />
        <div className="buttonContainer">
          <Button className="default-btn" onClick={() => refreshData()}>
            Refresh
          </Button>
          <Button
            className="default-btn"
            onClick={() => {
              history.push({
                pathname: '/',
              });
            }}
          >
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </div>
      </Form>
    </section>
  );
}
export default SfErrorDetails;

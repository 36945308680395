export const BASE_URL =
  process.env.REACT_APP_QL_SERVICE || 'http://localhost:5000';
export const GET_DASHBOARD = `${BASE_URL}/admin/dashboard`;
export const GET_ALL_ASSIGNEE = `${BASE_URL}/admin/getAllAssignee`;
export const GET_REVIEW_BY_REVIEW_ID = (reviewId) =>
  `${BASE_URL}/admin/dashboard/${reviewId}`;
export const GET_SFREVIEW_BY_REVIEW_ID = (reviewId) =>
  `${BASE_URL}/admin/dashboard/sf/${reviewId}`;
export const UPDATE_RECORD = `${BASE_URL}/admin/updateRecord`;
export const UPDATE_ERROR_DETAILS = `${BASE_URL}/admin/updateErrorDetails`;
export const DELETE_ERROR_DETAILS_BY_REVIEW_IDS = `${BASE_URL}/admin/deleteErrorDetailsByReviewIds`;
export const UPDATE_SF_ERROR_DETAILS = `${BASE_URL}/admin/updateSfErrorDetails`;
export const UPDATE_ERROR_DASHBOARD_COMMENTS = `${BASE_URL}/admin/updateErrorDashboardComments`;
export const UPDATE_ERROR_DETAIL_COMMENTS = `${BASE_URL}/admin/updateErrorDetailComments`;
export const UPDATE_SF_ERROR_DETAIL_COMMENTS = `${BASE_URL}/admin/updateSfErrorDetailComments`;
export const GET_FAILED_RECORDS = `${BASE_URL}/admin/getFailedRecords`;
export const DELETE_FAILED_RECORDS_BY_REQUEST_IDS = `${BASE_URL}/admin/deleteFailedRecordsByRequestIds`;
export const UPDATE_FAILED_RECORDS_STATUSES_BY_REQUEST_IDS = `${BASE_URL}/admin/updateFailedRecordsStatusesByRequestIds`;

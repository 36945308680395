import React from 'react';
import { Table } from 'antd';

const xrefColumns = [
  {
    title: 'Global Xref Details',
    children: [
      {
        title: 'Source System',
        dataIndex: 'source_system',
        width: 150,
      },
      {
        title: 'Account Group',
        dataIndex: 'account_group',
        width: 150,
      },
      {
        title: 'Source Partner Number',
        dataIndex: 'source_partner_number',
        width: 150,
      },
      {
        title: 'Marked For Deletion',
        dataIndex: 'deleted',
        width: 150,
        render: (col, row) => (row.deleted === 1 ? 'X' : ''),
      },
    ],
  },
];

export const XrefDetails = ({ xrefData }) => {
  return (
    <Table
      columns={xrefColumns}
      dataSource={xrefData}
      rowKey={(record) => record.id}
      pagination={false}
    />
  );
};

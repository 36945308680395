import React from 'react';
import { Input } from 'antd';
const { TextArea } = Input;

const handleCommentChange = (event, recordId, comments, setComments) => {
  setComments({
    ...comments,
    [recordId]: event.target.value,
  });
};

const CommentBox = (props) => {
  const { recordId, comments, setComments } = props;
  return (
    <TextArea
      autoSize
      onClick={(event) => event.stopPropagation()}
      onChange={(event) =>
        handleCommentChange(event, recordId, comments, setComments)
      }
      value={comments[recordId]}
    />
  );
};

export default CommentBox;

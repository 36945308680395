import React from 'react';

function OriginalAccount({ apiData }) {
  return (
    <table className="simpleTable originalAccountTable">
      <thead>
        <tr>
          <th colSpan="3">Original Account Information</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="title">Mailing Address</td>
          <td className="title">Physical Address</td>
          <td className="title">System Information</td>
        </tr>
        <tr>
          <td colSpan="2">
            Name : {(apiData && apiData.grower_legal_name) || null}
          </td>
          <td>BP ID : {(apiData && apiData.grower_bp_id) || null}</td>
        </tr>
        <tr>
          <td>House No : {(apiData && apiData.house_number) || null}</td>
          <td>House No : {(apiData && apiData.house_number) || null}</td>
          <td>AGIIS GLN : {(apiData && apiData.grower_gln) || null}</td>
        </tr>
        <tr>
          <td colSpan="2">
            P.O Box : {(apiData && apiData.grower_po_box) || null}{' '}
          </td>
          <td> FSAP : {(apiData && apiData.grower_fsap_id) || null}</td>
        </tr>
        <tr>
          <td>
            Street :{' '}
            {(apiData && apiData.grower_mailing_address_line_1) || null}{' '}
          </td>
          <td>
            Street :{' '}
            {(apiData && apiData.grower_shipping_address_line_1) || null}{' '}
          </td>
          <td> SFDC : {(apiData && apiData.grower_sfdc_id) || null}</td>
        </tr>
        <tr>
          <td>State : {(apiData && apiData.grower_mailing_state) || null} </td>
          <td>State : {(apiData && apiData.grower_shipping_state) || null} </td>
          <td> PROP ID : {(apiData && apiData.prop_id) || null}</td>
        </tr>
        <tr>
          <td>City : {(apiData && apiData.grower_mailing_city) || null}</td>
          <td>City : {(apiData && apiData.grower_shipping_city) || null}</td>
          <td>
            Resellers FSAP : {(apiData && apiData.reseller_fsap_id) || null}
          </td>
        </tr>
        <tr>
          <td>
            Zip : {(apiData && apiData.grower_mailing_postal_code) || null}
          </td>
          <td>
            Zip : {(apiData && apiData.grower_shipping_postal_code) || null}
          </td>
          <td>
            {' '}
            Sales Div : {(apiData && apiData.reseller_sales_division) || null}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
export default OriginalAccount;

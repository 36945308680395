import React from 'react';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const STATUSES = new Set(['Open', 'In Progress', 'Closed']);

const onClickDropdownOption =
  (
    failedRecord,
    failedRecordStatusUpdates,
    setFailedRecordStatusUpdates,
    setFailedRecords,
    failedRecords
  ) =>
  ({ key: newStatus }) => {
    const failedRecordIndex = failedRecords.findIndex(
      (record) => record.request_id === failedRecord.request_id
    );
    if (failedRecordIndex > -1) {
      const newFailedRecords = failedRecords;
      newFailedRecords[failedRecordIndex].status = newStatus;

      const newFailedRecordStatusUpdates = Array.from(
        failedRecordStatusUpdates
      );
      const indexOfFailedRecordInStatusUpdates =
        newFailedRecordStatusUpdates.findIndex(
          (statusUpdate) => statusUpdate?.requestId === failedRecord.request_id
        );
      if (indexOfFailedRecordInStatusUpdates > -1) {
        newFailedRecordStatusUpdates[
          indexOfFailedRecordInStatusUpdates
        ].status = newStatus;
      } else {
        newFailedRecordStatusUpdates.push({
          requestId: failedRecord.request_id,
          status: newStatus,
        });
      }

      setFailedRecords(newFailedRecords);
      setFailedRecordStatusUpdates(newFailedRecordStatusUpdates);
    }
  };

function getDropdownOptions(
  status,
  failedRecord,
  failedRecordStatusUpdates,
  setFailedRecordStatusUpdates,
  setFailedRecords,
  failedRecords
) {
  const availableStatuses = new Set(STATUSES);
  availableStatuses.delete(status);
  const statusOptions = [];
  availableStatuses.forEach((availableStatus, index) => {
    statusOptions.push(<Menu.Item key={index}>{availableStatus}</Menu.Item>);
  });

  return (
    <Menu
      onClick={onClickDropdownOption(
        failedRecord,
        failedRecordStatusUpdates,
        setFailedRecordStatusUpdates,
        setFailedRecords,
        failedRecords
      )}
    >
      {statusOptions}
    </Menu>
  );
}

function StatusDropdown(props) {
  const {
    status,
    failedRecord,
    failedRecordStatusUpdates,
    setFailedRecordStatusUpdates,
    setFailedRecords,
    failedRecords,
  } = props;
  return (
    <Dropdown
      key={failedRecord.request_id}
      overlay={getDropdownOptions(
        status,
        failedRecord,
        failedRecordStatusUpdates,
        setFailedRecordStatusUpdates,
        setFailedRecords,
        failedRecords
      )}
    >
      <span style={{ userSelect: 'none' }}>
        {status} <DownOutlined />
      </span>
    </Dropdown>
  );
}

export default StatusDropdown;

export const STATUS_LIST = [
  { key: 'Hold', value: 'Hold' },
  { key: 'Open', value: 'Open' },
  { key: 'Assigned', value: 'Assigned' },
  { key: 'Approved', value: 'Approved' },
  { key: 'Completed', value: 'Completed' },
  { key: 'Processed', value: 'Processed' },
];

// For Custom Table Cloned from GA

export const SORT_TYPE = {
  NUMBER: 'NUMBER',
  TEXT: 'TEXT',
  DATE: 'DATE',
};

export const FILTER_TYPE = {
  SELECT: 'SELECT',
  TEXT: 'TEXT',
  DATE: 'DATE',
};

export const numberSorter = (a, b, sortOrder, key) => {
  if (sortOrder === 'ascend') {
    if (a[key] === undefined) return 1;
    if (b[key] === undefined) return -1;
  } else {
    if (a[key] === undefined) return -1;
    if (b[key] === undefined) return 1;
  }
  if (a[key] === b[key]) return 0;
  return a[key] < b[key] ? -1 : 1;
};

export const stringSorter = (a, b, sortOrder, key, locale) => {
  if (sortOrder === 'ascend') {
    if (!a[key]) return 1;
    if (!b[key]) return -1;
  } else {
    if (!a[key]) return -1;
    if (!b[key]) return 1;
  }
  if (a[key] === b[key]) return 0;
  return new Intl.Collator(locale).compare(a[key].trim(), b[key].trim());
};

import axios from 'axios';
import { getHeaders } from '../utils/axiosUtils';
import {
  GET_FAILED_RECORDS,
  DELETE_FAILED_RECORDS_BY_REQUEST_IDS,
  UPDATE_FAILED_RECORDS_STATUSES_BY_REQUEST_IDS,
} from '../components/shared/ApiConstants';

/**
 * Gets failed records
 * @param {string} accessToken
 * @returns {Promise<Array<Object>>} failedRecords
 */
export async function getFailedRecords(accessToken) {
  const headers = getHeaders(accessToken);
  const { data } = await axios.get(GET_FAILED_RECORDS, { headers });

  return data;
}

/**
 * Delete failed records by request Ids
 * @param {number[]} failedRecordsToBeDeleted
 * @param {string} accessToken
 * @returns {Promise<void>}
 */
export async function deleteFailedRecords(
  failedRecordsToBeDeleted,
  accessToken
) {
  const headers = getHeaders(accessToken);

  if (failedRecordsToBeDeleted?.length > 0)
    await axios.post(
      DELETE_FAILED_RECORDS_BY_REQUEST_IDS,
      {
        requestIds: failedRecordsToBeDeleted,
      },
      { headers }
    );
}

/**
 * Update failed records' statuses.
 * @param {Array<{ requestId: number, status: string }>} failedRecordStatusUpdates
 * @param {string} accessToken
 * @returns {Promise<void>}
 */
export async function updateFailedRecordsStatuses(
  failedRecordStatusUpdates,
  accessToken
) {
  if (failedRecordStatusUpdates.length > 0) {
    const newStatusesToRequestIdsMap = {};
    failedRecordStatusUpdates.forEach(({ requestId, status }) => {
      if (newStatusesToRequestIdsMap[status]) {
        newStatusesToRequestIdsMap[status].push(requestId);
      } else {
        newStatusesToRequestIdsMap[status] = [requestId];
      }
    });
    const headers = getHeaders(accessToken);
    await axios.post(
      UPDATE_FAILED_RECORDS_STATUSES_BY_REQUEST_IDS,
      {
        newStatusesToRequestIdsMap,
      },
      { headers }
    );
  }
}

import React, { useCallback } from 'react';
import { Switch, Route } from 'react-router-dom';
import ErrorDashboard from './components/errorDashboard/ErrorDashboard';
import FailedRecords from './components/failedRecords/FailedRecords';
import SfErrorDetails from './components/errorDetails/SfErrorDetails';
import ErrorDetails from './components/errorDetails/ErrorDetails';
import { getOktaConfig } from './oktaConfig';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { toRelativeUrl, OktaAuth } from '@okta/okta-auth-js';
import { useHistory } from 'react-router-dom';

const oktaAuth = new OktaAuth(getOktaConfig(process.env.REACT_APP_ENV));

function Routes() {
  const history = useHistory();
  const restoreOriginalUri = useCallback(
    async (_oktaAuth, originalUri) => {
      const replacementUrl = toRelativeUrl(
        originalUri || '/',
        window.location.origin
      );
      history.replace(replacementUrl);
    },
    [history]
  );

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <SecureRoute exact path="/" component={ErrorDashboard} />
        <SecureRoute exact path="/index.html" component={ErrorDashboard} />
        <SecureRoute path="/details/:reviewId" component={ErrorDetails} />
        <SecureRoute path="/sfdetails/:reviewId" component={SfErrorDetails} />
        <SecureRoute path="/failedRecords" component={FailedRecords} />
        <Route path="/login/callback" component={LoginCallback} />
      </Switch>
    </Security>
  );
}

export default Routes;

import React, { Component } from 'react';
import { Table, Dropdown, Menu, Row, Col, Button } from 'antd';
import moment from 'moment';
import {
  SearchOutlined,
  DownloadOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import {
  trim,
  toLower,
  isArray,
  uniq,
  uniqBy,
  concat,
  isEqual,
  isPlainObject,
} from 'lodash';
import { SORT_TYPE, FILTER_TYPE } from './ApplicationConstants';
import TextSearch from './TextSearch';
import DateSearch from './DateSearch';
import './custom-table.less';

export class CustomTable extends Component {
  constructor(props) {
    super(props);
    this.state = this.getState();
  }

  componentDidUpdate(nextProps) {
    const { data, onRowSelect } = nextProps;
    const prevData = this.props.data;
    if (onRowSelect && !isEqual(data, prevData)) {
      this.setState({ selectedRowKeys: this.getInitialSelRows() });
    }
  }

  isFilteredTable = () => {
    const { searchText } = this.state;
    return !Object.keys(searchText || {}).every(
      (key) => !trim(searchText[key])
    );
  };

  getState = () => {
    const { onRowSelect, filterEnabled, isCSVExport } = this.props;
    let result = {};
    //if row selection needed
    if (onRowSelect) {
      result.selectedRowKeys = this.getInitialSelRows();
    }
    if (filterEnabled) {
      result.searchText = {};
      result.filteredData = [];
    }
    //headers if csv export is required
    if (isCSVExport) {
      result.headers = [];
    }
    return result;
  };

  getInitialSelRows = () => {
    const { data, initRowSelection } = this.props;
    if (isArray(initRowSelection)) {
      return initRowSelection;
    } else {
      return (data || []).reduce((selectedKeys, record) => {
        if (initRowSelection(record)) {
          selectedKeys.push(record.key);
        }
        return selectedKeys;
      }, []);
    }
  };

  sortByColumn = (key, sortType, record1, record2) => {
    let val1, val2;
    if (isPlainObject(record1[key])) {
      val1 = toLower(trim(record1[key].value));
      val2 = toLower(trim(record2[key].value));
    } else {
      val1 = toLower(trim(record1[key]));
      val2 = toLower(trim(record2[key]));
    }
    switch (sortType) {
      case SORT_TYPE.NUMBER:
        return Number(val1) - Number(val2);
      case SORT_TYPE.DATE:
        return moment(val2).unix() - moment(val1).unix();
      default:
        const { locale } = this.props;
        return new Intl.Collator(locale).compare(val1, val2);
    }
  };

  formColumns = () => {
    const { columns, data, colNameToSort } = this.props;
    let resColumns = [];
    let headers = [];
    (columns || []).forEach((col) => {
      let resultCol = {};
      !col.excludeCSV &&
        headers.push({
          label: col['title'],
          key: col['exportKey'] || col['dataIndex'],
        });
      Object.keys(col).forEach((key) => {
        switch (key) {
          case 'dataIndex':
          case 'title':
          case 'render':
          case 'fixed':
          case 'align':
          case 'width':
            resultCol[key] = col[key];
            break;
          case 'sort':
            const sortFunction = col['sortFunc'] || this.sortByColumn;

            if (colNameToSort) {
              if (colNameToSort === col.key) {
                resultCol.defaultSortOrder = col['sortOrd'] || 'ascend';
              }
              resultCol.sorter = col.sorter;
            } else {
              resultCol.sorter = sortFunction.bind(
                this,
                col.dataIndex,
                col[key]
              );
              resultCol.defaultSortOrder = col['sortOrd'] || 'ascend';
            }
            break;
          case 'filter':
            resultCol.onFilter = (val, record) => {
              const searchVal = toLower(trim(String(val)));
              if (val) {
                if (!this.state.searchText[col.dataIndex]) {
                  this.updateSearchKey(col.dataIndex, searchVal);
                }
              } else {
                this.updateSearchKey(col.dataIndex, searchVal);
              }
              const value = record[col.dataIndex];
              if (isArray(value)) {
                if ((value || []).length > 0) {
                  const isObj = isPlainObject(value[0]);
                  if (isObj) {
                    return (
                      (value || []).filter(
                        (op) => toLower(trim(String(op.key))) === searchVal
                      ).length > 0
                    );
                  } else {
                    return (value || []).indexOf(searchVal) > -1;
                  }
                }
              } else {
                const isObj = isPlainObject(value);
                const recordVal = isObj ? (value || {}).key : value;
                if (isObj) {
                  return toLower(trim(String(recordVal))) === searchVal;
                } else {
                  return (
                    toLower(trim(String(recordVal))).indexOf(searchVal) > -1
                  );
                }
              }
            };
            switch (col[key]) {
              case FILTER_TYPE.SELECT:
                let colValues = [];
                let isObj = false;
                let isArr = false;
                (data || []).forEach((rec) => {
                  let val = rec[col.dataIndex];
                  if (0 === val || val) {
                    isArr = isArray(val);
                    if (isArr) {
                      if ((val || []).length > 0) {
                        isObj = isPlainObject(val[0]);
                      }
                    } else {
                      isObj = isPlainObject(val);
                    }
                    colValues = concat(colValues, val);
                  }
                });
                if (isObj) {
                  resultCol.filters = uniqBy(colValues, 'key')
                    .filter((f) => trim(String(f.key)))
                    .map((filter) => ({
                      text: filter.value,
                      value: filter.key,
                    }));
                } else {
                  resultCol.filters = uniq(colValues)
                    .filter((f) => trim(String(f)))
                    .map((filter) => ({
                      text: filter,
                      value: filter,
                    }));
                }
                break;
              case FILTER_TYPE.TEXT:
                resultCol.filterDropdown = (options) => (
                  <TextSearch
                    {...options}
                    handleSearch={this.handleSearch.bind(this, col.dataIndex)}
                    handleReset={this.handleReset.bind(this, col.dataIndex)}
                    // commonMessages={commonMessages}
                  />
                );
                resultCol.filterIcon = (filtered) => (
                  <SearchOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                  />
                );

                break;
              case FILTER_TYPE.DATE:
                resultCol.filterDropdown = (options) => (
                  <DateSearch
                    {...options}
                    handleSearch={this.handleSearch.bind(this, col.dataIndex)}
                    handleReset={this.handleReset.bind(this, col.dataIndex)}
                  />
                );
                resultCol.filterIcon = (filtered) => (
                  <CalendarOutlined
                    style={{ color: filtered ? '#1890ff' : undefined }}
                  />
                );
                resultCol.onFilter = (val, record) => {
                  const startDate = val[0];
                  const endDate = val[1];
                  const value = record[col.dataIndex];
                  return (
                    startDate.isSameOrBefore(value, 'day') &&
                    endDate.isSameOrAfter(value, 'day')
                  );
                };

                break;
              default:
            }
            break;
          default:
        }
      });
      resColumns.push(resultCol);
    });
    if (!isEqual(this.state.headers, headers)) {
      this.setState({ headers: headers });
    }
    return resColumns;
  };

  onRowSelection = (record, selected, selectedRows) => {
    this.setState({
      selectedRowKeys: (selectedRows || []).map((row) => row.key),
    });
    const { onRowSelect } = this.props;
    onRowSelect && onRowSelect(record, selected, selectedRows);
  };

  onChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys: selectedRowKeys });
    const { onRowChange } = this.props;
    onRowChange && onRowChange(selectedRowKeys, selectedRows);
  };

  onAllRowsSelection = (selected, selectedRows, changeRows) => {
    this.setState({
      selectedRowKeys: (selectedRows || []).map((row) => row.key),
    });
    const { onAllRowSelect } = this.props;
    onAllRowSelect && onAllRowSelect(selected, selectedRows, changeRows);
  };

  getTableProps = () => {
    const { onRowSelect, filterEnabled, emptyText, components } = this.props;
    const { selectedRowKeys } = this.state;
    let options = {};
    if (onRowSelect) {
      options.rowSelection = {
        selectedRowKeys,
        onChange: this.onChange,
        onSelect: this.onRowSelection,
        onSelectAll: this.onAllRowsSelection,
      };
    }
    options.sortDirections = ['ascend', 'descend'];
    if (filterEnabled) {
      options.onChange = (pagination, filters, sorter, extra) => {
        this.setState({ filteredData: extra.currentDataSource });
      };
    }
    if (trim(emptyText)) {
      options.locale = {
        emptyText: emptyText,
      };
    }
    if (components) {
      options.components = { ...components };
    }
    return options;
  };

  handleSearch = (key, selectedKeys, confirm) => {
    confirm();
    this.updateSearchKey(key, selectedKeys[0]);
  };

  updateSearchKey = (key, searchText) => {
    if (this.state.searchText[key] !== searchText) {
      const nextSearchText = {
        ...this.state.searchText,
        [key]: searchText,
      };
      this.setState({ searchText: nextSearchText });
    }
  };

  handleReset = (key, clearFilters) => {
    clearFilters();
    this.updateSearchKey(key, '');
  };

  getExportButton = () => {
    const { data } = this.props;
    const menu = (
      <Menu>
        <Menu.Item key={0}>
          <CSVLink data={data} headers={this.state.headers}>
            {'Export All'}
          </CSVLink>
        </Menu.Item>
        <Menu.Item key={1}>
          <CSVLink data={this.state.filteredData} headers={this.state.headers}>
            {'Export Filtered'}
          </CSVLink>
        </Menu.Item>
      </Menu>
    );
    return (
      <Dropdown className="export-button" trigger={['click']} overlay={menu}>
        <Button>
          <DownloadOutlined />
          {'Export'}
        </Button>
      </Dropdown>
    );
  };

  render() {
    const {
      data,
      isCSVExport,
      pagination,
      onRowClick,
      bordered,
      scroll,
      rowSelection,
      rowKey,
    } = this.props;
    const options = this.getTableProps();
    const columns = this.formColumns();
    return (
      <div className="custom-table-cont">
        <Row justify="end" gutter={[0, 16]}>
          <Col offset={1}>{isCSVExport && this.getExportButton()}</Col>
        </Row>

        <Table
          columns={columns}
          dataSource={data}
          size="small"
          pagination={pagination ? pagination : false}
          bordered={bordered || false}
          scroll={scroll}
          rowSelection={rowSelection}
          onRow={(record) => ({
            onClick: () => {
              onRowClick && onRowClick(record);
            },
          })}
          rowKey={rowKey}
          {...options}
        />
      </div>
    );
  }
}

import React from 'react';
import './app.css';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { EnvironmentRibbon } from './components/shared/EnvironmentRibbon';

function App() {
  return (
    <section>
      {process.env.REACT_APP_ENV !== 'PRODUCTION' && <EnvironmentRibbon />}
      <React.Fragment>
        <Router>
          <Routes />
        </Router>
      </React.Fragment>
    </section>
  );
}

export default App;

/**
 * Returns okta configuration by environment. Defaults to local environment configuration if
 * environment is not one of the values specified for the environment param.
 * @param {string} environment LOCAL | TEST | STAGE | PRODUCTION
 * @returns {import('@okta/okta-auth-js').OktaAuthOptions} oktaAuthOptions
 */
export function getOktaConfig(environment) {
  switch (environment) {
    case 'PRODUCTION':
      return {
        issuer: 'https://signin.syngenta.com/oauth2/aus575jkuuZAOVhr74x6',
        clientId: '0oa6ttpr45rRqRxQx4x7',
        redirectUri:
          'https://ui.qualificationlayer.syngentadigitalapps.com/login/callback',
      };
    case 'STAGE':
      return {
        issuer: 'https://signin-test.syngenta.com/oauth2/ausqmgapztkTfvQg70h7',
        clientId: '0oa16slmjlgWkECQb0h8',
        redirectUri:
          'https://ui-stage.qualificationlayer.syngentadigitalapps.com/login/callback',
      };
    case 'TEST':
      return {
        issuer: 'https://signin-test.syngenta.com/oauth2/ausqmgapztkTfvQg70h7',
        clientId: '0oa16r9kd6yd8lXUq0h8',
        redirectUri:
          'https://ui-test.qualificationlayer.syngentadigitalapps.com/login/callback',
      };
    case 'LOCAL':
    default:
      return {
        issuer: 'https://signin-test.syngenta.com/oauth2/ausqmgapztkTfvQg70h7',
        clientId: '0oa16r9kd6yd8lXUq0h8',
        redirectUri: 'http://localhost:3000/login/callback',
      };
  }
}

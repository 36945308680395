import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { Button } from 'antd';
import {
  getFailedRecords,
  deleteFailedRecords,
  updateFailedRecordsStatuses,
} from '../../apis/failedRecordsApis';
import { CustomTable } from '../shared/CustomTable';
import { FILTER_TYPE, SORT_TYPE } from '../shared/ApplicationConstants';
import StatusDropdown from './StatusDropdown';

function FailedRecords() {
  const history = useHistory();
  const [failedRecords, setFailedRecords] = useState([]);
  const [size, setSize] = useState(localStorage.getItem('errorPageSize') || 10);
  const [failedRecordsToBeDeleted, setFailedRecordsToBeDeleted] = useState([]);
  const [failedRecordStatusUpdates, setFailedRecordStatusUpdates] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const { authState } = useOktaAuth();
  const accessToken = authState.accessToken?.accessToken;

  async function getAndSetFailedRecords() {
    const failedRecordsResponse = await getFailedRecords(accessToken);
    setFailedRecords(failedRecordsResponse);
  }

  useEffect(() => {
    getAndSetFailedRecords();
  }, [size]);

  const isSaveButtonDisabled = () =>
    failedRecordsToBeDeleted.length < 1 && failedRecordStatusUpdates.length < 1;

  const renderStatusCell = (status, failedRecord) => (
    <StatusDropdown
      status={status}
      failedRecord={failedRecord}
      failedRecordStatusUpdates={failedRecordStatusUpdates}
      setFailedRecordStatusUpdates={setFailedRecordStatusUpdates}
      setFailedRecords={setFailedRecords}
      failedRecords={failedRecords}
    />
  );

  const columns = [
    {
      title: 'Request Id',
      dataIndex: 'request_id',
      filter: FILTER_TYPE.TEXT,
      sort: SORT_TYPE.TEXT,
      sortOrd: 'ascend',
    },
    {
      title: 'Created Date',
      dataIndex: 'created',
      width: 200,
      filter: FILTER_TYPE.TEXT,
      sort: SORT_TYPE.TEXT,
      sortOrd: 'ascend',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filter: FILTER_TYPE.TEXT,
      sort: SORT_TYPE.TEXT,
      sortOrder: 'ascend',
      render: renderStatusCell,
    },
    {
      title: 'Failed Description',
      dataIndex: 'description',
      filter: FILTER_TYPE.TEXT,
      sort: SORT_TYPE.TEXT,
      sortOrd: 'ascend',
    },
    {
      title: 'Grower Name',
      dataIndex: 'grower_legal_name',
      filter: FILTER_TYPE.TEXT,
      sort: SORT_TYPE.TEXT,
      sortOrd: 'ascend',
    },
    {
      title: 'GLN',
      dataIndex: 'grower_gln',
      filter: FILTER_TYPE.TEXT,
      sort: SORT_TYPE.TEXT,
      sortOrd: 'ascend',
    },
    {
      title: 'SFDC ID',
      dataIndex: 'grower_sfdc_id',
      filter: FILTER_TYPE.TEXT,
      sort: SORT_TYPE.TEXT,
      sortOrd: 'ascend',
    },
  ];

  function onChangeSize(current, pageSize) {
    setSize(pageSize);
    localStorage.setItem('errorPageSize', pageSize);
  }

  const clearQueuedChanges = () => {
    setFailedRecordsToBeDeleted([]);
    setFailedRecordStatusUpdates([]);
  };

  const onPressSave = async () => {
    setIsLoading(true);
    await updateFailedRecordsStatuses(failedRecordStatusUpdates, accessToken);
    await deleteFailedRecords(failedRecordsToBeDeleted, accessToken);
    await getAndSetFailedRecords();
    clearQueuedChanges();
    setIsLoading(false);
  };

  /**
   * Toggles marking a failed record ready to be deleted
   *
   * @param {Object} failedRecord
   * @param {boolean} isSelected
   */
  const onSelectFailedRecordToDelete = (failedRecord, isSelected) => {
    if (isSelected) {
      setFailedRecordsToBeDeleted((keys) => [...keys, failedRecord.request_id]);
    } else {
      setFailedRecordsToBeDeleted((keys) => {
        const index = keys.indexOf(failedRecord.request_id);
        return [...keys.slice(0, index), ...keys.slice(index + 1)];
      });
    }
  };

  const rowSelection = {
    selectedRowKeys: failedRecordsToBeDeleted,
    onSelect: onSelectFailedRecordToDelete,
    columnTitle: 'Delete',
    fixed: true,
    hideSelectAll: true,
  };

  return (
    <section className="container">
      <div className="flex">
        <div className="width50 header textRight">List of Failed Records </div>
        <div className="width50 textRight">
          <Button
            type="primary"
            className="textRightButton"
            onClick={() => {
              history.push({
                pathname: '/',
              });
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <CustomTable
        data={failedRecords}
        filterEnabled={true}
        isCSVExport={true}
        emptyText="No Data"
        columns={columns}
        pagination={{
          onShowSizeChange: onChangeSize,
          showSizeChanger: true,
          defaultCurrent: 1,
          pageSize: size,
        }}
        rowKey={(record) => record.request_id}
        rowSelection={rowSelection}
      />
      {Boolean(failedRecords.length) && (
        <div className="buttonContainer">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            onClick={onPressSave}
            disabled={isSaveButtonDisabled()}
          >
            Save
          </Button>
        </div>
      )}
    </section>
  );
}
export default FailedRecords;
